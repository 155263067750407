import React from "react";
import "../../style/advocacy.css";
import ChildImg from '../../assets/images/Mindmap_Complete_Wide.png'
function Advocacy() {
  return (
    <div className="advocacy-home-container">
      <div className="sub-advocacy-container">
      <div className="advocacy-view-row">
          <img
            src={ChildImg}
            className="advocacy-img-first"
            alt="dashboardImg2"
          />
        </div>
      </div>
    </div>
  );
}

export default Advocacy;
