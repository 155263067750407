import React, { useEffect, useState } from "react";
import { Rating } from "@mui/material";
import "../../style/home.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import iconsImg from "../../assets/images/vectorImg.png";

const testimonials = [
  {
    id: 1,
    icons: iconsImg,
    title: "A tool for connection",
    name: "Tricia Bowen",
    para: "Katelyn’s mom",
    rating: 5,
    textPlaceholder:
      "Oh my gosh. This is so good and so special. I cannot wait to show it to her. She is having a hard time at dance class and I feel like this could be so good for her instructors, for the kids in her class.",
  },
  {
    id: 2,
    icons: iconsImg,
    title: "The power of the story",
    name: "Marcy Renken",
    para: "Will’s mom",
    rating: 5,
    textPlaceholder:
      "Every time we shared Will’s book, it transformed the way people, especially teachers, supported our son. It has created empathy and understanding for our son who struggles with an invisible disability.",
  },
  {
    id: 3,
    icons: iconsImg,
    title: "Using stories for good",
    name: "Dr. Terry Terhune",
    para: "Superintendent",
    study: "Greenwood Community School Corporation",
    rating: 4,
    textPlaceholder:
      "A few things stood out for me. A journey of overcoming life and difficulties that are presented, for not only you but especially for Will. And then, a way to turn that around to help other people. That’s what’s really inspiring.",
  },
  {
    id: 4,
    icons: iconsImg,
    title: "Creating Supportive siblings",
    name: "Dominic Renken",
    para: "Will's brother",
    rating: 5,
    textPlaceholder:
      "It describes what troubles he went through. I want other people to know what Will went through and the struggles that he deals with on a regular basis. He’s a loving brother who cares for his younger siblings, most of the time.",
  },
];

function Testimonial() {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 767) {
        setItemsPerPage(1);
      } else {
        setItemsPerPage(3);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePrevious = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNext = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(testimonials.length / itemsPerPage) - 1)
    );
  };

  const startIndex = currentPage * itemsPerPage;
  const selectedTestimonials = testimonials.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  return (
    <div className="home-Testimonial-container">
      <div className="sub-Testimonial-container">
        <div className="Testimonial-content-container">
          <span className="testimonial-heading-text">Testimonials</span>
          <p className="testimonial-para-text">
            Voices of Success: Our
            <br />
            Community's Testimonials
          </p>
        </div>
        <div className="card-view-profile-container">
          {selectedTestimonials.map((testimonial) => (
            <div key={testimonial.id} className="testimonial-card">
              <div>
                <img
                  src={testimonial.icons}
                  alt="icon"
                  className="icon-img-testimonial-page"
                />
              </div>
              <div className="testimonial-card-title-line">
                <p className="testimonial-card-title-text">
                  “{testimonial?.title}”
                </p>
                <hr className="testimonial-center-line" />
              </div>
              <p className="testimonial-card-textPlaceholder-text">
                {testimonial.textPlaceholder}
              </p>
                 <span className="testimonial-card-heading-text">
                  {testimonial.name}
                </span>
                <span className="testimonial-card-para-text">
                  {testimonial.para}
                </span>
                {testimonial.study && (
                  <span className="testimonial-card-study-text">
                    {testimonial.study}
                  </span>
                )}
                <Rating
                  name={`rating-${testimonial.id}`}
                  value={testimonial.rating}
                  readOnly
                />
            </div>
          ))}
        </div>
        <div className="pagination">
          <button onClick={handlePrevious} disabled={currentPage === 0}>
            <FaArrowLeft size={16} />
          </button>
          <button
            onClick={handleNext}
            disabled={
              currentPage === Math.ceil(testimonials.length / itemsPerPage) - 1
            }
          >
            <FaArrowRight size={16} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
