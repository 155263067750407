import React from "react";
import "../../style/home.css";
import dashboardImg2 from "../../assets/images/dashboardImg2.png";
import dashboardImg1 from "../../assets/images/dashboardImg1.png";
import circleLine from "../../assets/images/circleLIne.png";
import ChildImgOne from "../../assets/images/home-childImgOne.png";
import ChildImgTwo from "../../assets/images/home-childImgTwo.png";
import circleLineOne from "../../assets/images/circleOne.png";
import circleLineTwo from "../../assets/images/circleTwo.png";
import headImg from "../../assets/images/text-head-img.png"
import AboutUs from "./AboutUs";
import Works from "./Works";
import Book from "./Book";
import FAQ from "./FAQ";
import Story from "./Story";
import Testimonial from "./Testimonial";
import Footer from "../../components/nav-bar/Footer";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";
import Advocacy from "./Advocacy";
import Video from "./Video";
// import { FaTimes } from "react-icons/fa";

function Dashboard() {
  const navigate = useNavigate();

  const handleClick = () => {
    ReactGA.event({
      category: "Form",
      action: "Navigate to form page",
    });
    navigate("/form");
  };

  // const handleVideoClose = () => {
  //   setIsVideoVisible(false);
  // };

  return (
    <div className="dashboard-page">
      <div className="home-container-page">
        <div className="home-first-pages">
          <div className="home-img-content-box">
            <div className="imge-circle-view">
              <img
                src={dashboardImg2}
                className="img-childern-one-homePage"
                alt="dashboardImg2"
              />
            </div>
            <div className="content-btn-container">
              <div className="home-heading-line-container">
                <span className="home-heading-para">
                  “Struggling and exhausted from explaining your child’s needs?”
                </span>
                <hr className="home-center-line" />
                <span className="home-heading-para-ans">
                  A 3-minute Journey Book shares their story, helping others
                  truly understand and support their unique journey.
                </span>
              </div>
              <div style={{ marginTop: "20px" }}>
              <img src={headImg} alt="img" width={"25.37px"} height={"31.14px"} className="img-heading-home"/>
                <span className="home-page-text">Craft Your Own</span>
                <h1 className="second-heading-text-home">Journey Books</h1>
              </div>
              <div style={{ marginTop: "20px" }}>
                <p className="home-page-para-text">
                  Personalized stories celebrating every child's unique path
                </p>
              </div>
              <div
                style={{
                  alignContent: "center",
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                  padding: "20px",
                  marginTop: "30px",
                }}
              >
                <button className="custom-button" onClick={handleClick}>
                  Get Started Today
                </button>
              </div>
            </div>
          </div>
          <div className="home-imgs-content-row">
            <div className="circle-line-img-view">
              <img
                src={circleLine}
                className="img-home-page-circle"
                alt="dashboardImg2"
              />
            </div>
            <div className="imge-circle-view-row">
              <img
                src={dashboardImg1}
                className="img-children-homePage-two"
                alt="dashboardImg2"
              />
            </div>
          </div>
        </div>
        <div className="home-first-pages-mobile">
          <div className="home-mobile-first-container">
            <div className="home-mobile-first-img--align">
              <img
                src={circleLineTwo}
                alt="circleTwo"
                width={"37.92px"}
                height={"60px"}
              />
              <img
                src={circleLineOne}
                alt="circleLineOne"
                width={"40.91px"}
                height={"78.27px"}
              />
            </div>
            <div className="content-btn-container">
              <div className="home-heading-line-container">
                <span className="home-heading-para">
                  “Struggling and exhausted from explaining your child’s needs?”
                </span>
                <hr className="home-center-line" />
                <span className="home-heading-para-ans">
                  A 3-minute Journey Book shares their story, helping others
                  truly understand and support their unique journey.
                </span>
              </div>
            </div>
          </div>
          <div className="home-mobile-second-container">
            <div>
              <img
                src={ChildImgOne}
                alt="circleLineOne"
                width={"82.16px"}
                height={"115.35px"}
              />
            </div>
            <div>
              <div style={{ textAlign: "center" }}>
              <img src={headImg} alt="img" width={"20px"} height={"25px"}/>
                <span className="home-page-text">Craft Your Own</span>
                <h1 className="second-heading-text-home">Journey Books</h1>
              </div>
              <div style={{ marginTop: "10px" }}>
                <p className="home-page-para-text">
                  Personalized stories celebrating every child's unique path
                </p>
              </div>
              <div
                style={{
                  alignContent: "center",
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                  padding: "20px",
                }}
              >
                <button className="custom-button" onClick={handleClick}>
                  Get Started Today
                </button>
              </div>
            </div>
            <div>
              <div className="circle-line-img-view">
                <img
                  src={circleLine}
                  className="img-home-page-circle"
                  alt="dashboardImg2"
                />
              </div>
              <div>
                <img
                  src={ChildImgTwo}
                  alt="circleLineOne"
                  width={"67.96px"}
                  height={"95.32px"}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Advocacy />
        </div>
        <div>
          <Video />
        </div>
        <div>
          <AboutUs />
        </div>
        <div>
          <Works />
        </div>
        <div>
          <Book />
        </div>
        <div>
          <FAQ />
        </div>
        <div>
          <Story />
        </div>
        <div>
          <Testimonial />
        </div>
        <div style={{ marginTop: "50px" }}>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
