import React, { useState } from "react";
import "../../style/video.css";
import ChildImg from "../../assets/images/video-childImg.png";
import {
  Player,
  ControlBar,
  PlaybackRateMenuButton,
  VolumeMenuButton,
} from "video-react";
import "video-react/dist/video-react.css";
import "../../style/home.css";

function Video() {
  const [isVideoVisible, setIsVideoVisible] = useState(true);

  return (
    <div className="video-home-container">
      <div className="sub-video-container">
        <div className="video-view-row">
          <img src={ChildImg} className="video-img-first" alt="dashboardImg2" />
        </div>
        {isVideoVisible && (
          <div className="video-container">
            <Player
              poster="/assets/poster.png"
              fluid={false}
              width={680}
              height={420}
            >
              <source src="https://stream.journeybookstories.com/home.mp4" />
              <ControlBar
                autoHide={true}
                disableCompletely={true}
                className="custom-control-bar"
              >
                <PlaybackRateMenuButton
                  rates={[5, 2, 1, 0.5, 0.1]}
                  className="custom-playback-rate-btn"
                />
                <VolumeMenuButton disabled className="custom-volume-btn" />
              </ControlBar>
            </Player>
          </div>
        )}
        {isVideoVisible && (
          <div className="text-btn-content-mobile">
            <Player
              poster="/assets/poster.png"
              fluid={false}
              width={350}
              height={200}
            >
              <source src="https://stream.journeybookstories.com/home.mp4" />
              <ControlBar
                autoHide={true}
                disableCompletely={true}
                className="custom-control-bar"
              >
                <PlaybackRateMenuButton
                  rates={[5, 2, 1, 0.5, 0.1]}
                  className="custom-playback-rate-btn"
                />
                <VolumeMenuButton disabled className="custom-volume-btn" />
              </ControlBar>
            </Player>
          </div>
        )}
      </div>
    </div>
  );
}

export default Video;
